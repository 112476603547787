.sub-header-about {
	.flex {
		align-items: flex-end;
		@include below(md) {
			justify-content: flex-end;
		}
	}

	.hero-pattern {
		transform-origin: right bottom;
		transform: scale(1.15) translate(15px, 45px);
	}
}

.team-section {
	.team-member-image-wrapper {
		.more-button {
			@include below(sm) {
				font-size: 16px;
				bottom: 16px;
				padding: 8px 14px;
			}
		}
	}
}

// reveal
.reveal {
	// padding: 100px 0;
	display: block !important;
	pointer-events: none;
	background: unset;
	padding: 0;
	top: 0px;
	left: 0px;
	margin: 0px;
	bottom: 0;
	height: 100% !important;
	overflow: scroll;
	-webkit-overflow-scrolling: auto;

	.img-wrapper {
		@include below(sm) {
			margin-bottom: 50px;
		}
	}

	.close-button {
		position: relative;
		display: block;
		margin: 25px;
		margin-left: auto;
		margin-bottom: 0;
		border: 2px solid #aaa;
		background-size: cover;
		border-radius: 50%;

		@include below(md) {
			$base: 40px;
			border-width: 1px;
			width: $base;
			height: $base;
			margin: $base-padding-mobile * 1px;
			margin-bottom: 0;
			margin-left: auto;
		}
	}

	.reveal-data {
		padding: 50px 0;
		@include below(md) {
			padding: 25px 0;
		}
	}

	.overlay {
		background: #fff;
		opacity: 0;
		transition: .3s all ease;
		position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -2;
	}

	.close-button {
		opacity: 0;
		transition: .3s all ease;
	}

	.anim-item {
		transition: .3s all ease-out;
		transform: translateY(25px);
	}

	// reveal is active
	&[aria-hidden="false"] {
		pointer-events: auto;

		.overlay,
		.close-button {
			opacity: 1;
		}

		.anim-item {
			opacity: 1;
			transform: none;
		}

		$elements: 6;
		@for $i from 0 to $elements {
			.anim-item:nth-child(#{$i + 1}) {
				transition-delay: ($i * .15s);
			}
		}
	}
}
