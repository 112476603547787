.testimonial-second {
  padding: rem-calc(150) 0;

  p {
    color: $body-font-color;
    font-size: rem-calc(24);
  }

  .testimonial-author p {
    font-family: $header-font-family;
  }
}