.sticky-navigation {
  position: fixed;
  top: 50%;
  // left: 5px;
	left: 0;
  z-index: 1000;
  transform: translateY(-50%);


  .sticky-items {
    margin: 0;
    padding: 0;

    .sticky-item a {
      display: inline-block;
      background-color: $sticky-nav-color;
      width: rem-calc(18);
      height: rem-calc(10);
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
      margin-bottom: rem-calc(18);
      transition: width $transition-speed-fast linear, background-color $transition-speed-fast linear;

      &:last-child {
        margin-bottom: 0;
      }

      &:hover {
        width: rem-calc(45);
      }

      &.is-active {
        background-color: darken($primary-color, 10%);
        width: rem-calc(45);
      }
    }
  }
}
