.contact-form {
  padding: rem-calc(110) 0 rem-calc(70);

  ul {
    list-style: none;
  }

  form > .validation_error {
    display: none;
  }

  .button {
    @include button-style($submit-button-purple, darken($submit-button-purple, 10%), $white);
    width: 100%;
    line-height: rem-calc(58);
    padding: 0 rem-calc(50);
    text-align: left;
    border-radius: rem-calc(80);

    @include breakpoint(medium) {
      width: rem-calc(370)
    }
  }

  .gfield_error {
    input,
    textarea {
      margin-bottom: 0;
    }

    .validation_message {
      margin-bottom: rem-calc(16);
      color: $alert-color;
    }
  }

  label {
    display: none;
  }

  .contact-form-offset {
    margin-top: rem-calc(60);

    @include breakpoint(tablet-portrait) {
      margin: 0;
    }
  }

  input {
    line-height: rem-calc(60);
    height: rem-calc(60);
    padding: 0 rem-calc(45);
    border-radius: 30px;
  }

  textarea {
    border-radius: rem-calc(20);
    padding: rem-calc(30 45);
    min-height: rem-calc(250);
    margin-bottom: rem-calc(16);
  }
}
