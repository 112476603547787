.navigation {
  padding: rem-calc(50) 0 rem-calc(40);
  //transition: padding $transition-speed-normal linear;
  // background-color: $dirty-white;
  .page-template-page-home & {

    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+100&0+1,1+100 */
    background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 1%, rgba(0, 0, 0, 1) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 1%, rgba(0, 0, 0, 1) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 1%, rgba(0, 0, 0, 1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#000000', GradientType=0); /* IE6-9 */

  }
  .is-stuck & {
    background: transparent;
  }
  @include breakpoint(small only) {
    padding: 40px 0 40px;
  }
  .navbar-brand {

    display: inline-block;
    height: 100%;
    width: 100%;

    a {
      position: relative;
      display: block;

      img {
        max-width: 270px;
        width: 100%;
        // max-height: rem-calc(70);
        transition: max-height $transition-speed-normal linear;
      }
    }
  }
}

.sticky-container {
  transition: background-color $transition-speed-normal linear;
}

.is-stuck {
  .navigation {
    padding: rem-calc(30) 0 rem-calc(20);
    background-color: $white;

    .navbar-brand img {
      max-width: 195px;

      width: 100%;
      // max-height: rem-calc(50);
    }
  }
}

.menu {
  margin: rem-calc(9) 0;

  .current-menu-item:not(.button) {
    span {
      font-weight: bold;
      &:after {
        display: block;
      }
    }
  }

  li {
    &:not(.button) {
      a {
        padding: rem-calc(18 25);
      }
    }

    &.button {
      a {
        &:hover {
          color: $white;

          span:after {
            display: none;
          }
        }
      }
    }

    &:first-child a {
      padding: rem-calc(18 0 18 25);
    }
  }

  a {
    color: $black;
    font-weight: 400;

    span {
      position: relative;

      &:after {
        content: '';
        display: none;
        height: 2px;
        width: 100%;
        position: absolute;
        left: 0;
        bottom: -5px;
        background-color: $black;
      }
    }

    &:hover span:after {
      display: block;
    }
  }
}