@include breakpoint(small only){
  .navigation {
    padding: 20px 0!important;
  }
  .sub-header .sub-header-wrapper {
    min-height: auto;
    padding: 2.75rem 20px;
  }
  .home-header #home-video-header .bg-image {
    width:auto;
    height:100vh;
  }
  .sub-header .sub-header-wrapper .subheader-subtitle {
    // REMOVE STYLING on this subheader subtitle on mobile
    /* font-weight: 100; */
    /* font-size: 1.5rem; */
    font-weight: normal;
    font-size: initial;
  }
  .featured-section .featured-card-section, .featured-section .featured-default-section {
    /* padding: 75px 0 75px; */
    padding: 0;
  }
  .featured-section .featured-card-section {
    /* padding: 2.5rem 0 3.75rem; */
    padding: 0;
  }
  .featured-section .featured-card-section.card-section-in-middle {
    padding:0;
  }
  .featured-section .featured-card-section {
    margin-bottom:40px;
  }
  .featured-section .featured-number {
    font-size: 3rem;
    padding-bottom:20px;
  }
  .sticky-container {
    margin-top:40px;
  }
  .statistics-wrapper {
    margin-bottom:20px;
  }
  .intro-section .intro-text {
    font-size: 1rem;
    font-weight: 300;
  }
  .home-header h1.home-banner-title {
    font-size:1.4em;
    width:auto;
    padding:0;
  }
}
@include breakpoint(large down){
  .main-menu{
    .button.white-hollow-blue-fill{
      border: none;
      padding: 1.125rem 1.5625rem;
      text-align: left;
    }
  }

}
@include breakpoint(large up){
  .main-menu{
    text-align:center;
  }
  .featured-default-content {
    padding-right: 1.875rem;
  }
  // .featured-section .featured-default-content  {padding-right:6rem;}
  // .featured-section .featured-card-section {padding:2rem;}
  // .featured-section .featured-default-section {padding:2rem;}
  // .featured-section .featured-card-section.card-section-in-middle {padding:2rem;}

  // .featured-section .featured-default-content {
  //   margin-top: 7.1875rem;
  //   top: 0px;
  //   -webkit-transform: none;
  //   -ms-transform: none;
  //   transform: none;
  // }
  [type='text'], [type='password'], [type='date'], [type='datetime'], [type='datetime-local'], [type='month'], [type='week'], [type='email'], [type='number'], [type='search'], [type='tel'], [type='time'], [type='url'], [type='color'], textarea {
    border-radius: 30px;
  }
  input::-webkit-input-placeholder,
  input::-moz-placeholder,
  input:-ms-input-placeholder,
  input:-o-input-placeholder {
    color: #000;
    font-weight: bold;
  }


}

