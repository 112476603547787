$breakpoints: ('sm', 'md', 'lg');
$reducer: .02;
$values: (
	xs: 0,
	sm: 576,
	md: 768,
	lg: 992,
	xl: 1200
);

// ******************** //
// breakpoints
// ******************** //

// new breakpoints
@mixin above($val, $int: false) {
	@media (min-width: if($int, $val * 1px, map-get($values, $val) * 1px)) {
		@content;
	}
}

@mixin below($val, $int: false) {
	@if($val != 'xs') {
		@media (max-width: if($int, ($val - $reducer) * 1px, (map-get($values, $val)-$reducer) * 1px)) {
			@content;
		}
	}
}

@mixin only($val) {
	@if $val == 'xs' {
		@media (max-width: (map-get($values, 'sm')-$reducer) * 1px) {
			@content;
		}
	}
	@else if $val == 'sm' {
		@media (min-width: map-get($values, $val)*1px) and (max-width: (map-get($values, 'md')-$reducer) * 1px) {
			@content;
		}
	}
	@else if $val == 'md' {
		@media (min-width: map-get($values, $val)*1px) and (max-width: (map-get($values, 'lg')-$reducer) * 1px) {
			@content;
		}
	}
	@else if $val == 'lg' {
		@media (min-width: map-get($values, $val)*1px) and (max-width: (map-get($values, 'xl')-$reducer) * 1px) {
			@content;
		}
	}
	@else {
		@media (min-width: map-get($values, 'xl')*1px) {
			@content;
		}
	}
}

@mixin between($min, $max, $int: false) {
	@media (min-width: if($int, $min*1px, map-get($values, $min)*1px)) and (max-width: if($int, ($max - $reducer)*1px, (map-get($values, $max)-$reducer)*1px)) {
		@content;
	}
}
