// additional styles
$headerHeight: 100px;
$headerHeightMobile: 76px;
@include heroComplex($headerHeight);
@include below(md) {
	@include heroComplex($headerHeightMobile);
}

#hero {
	position: relative;
	@include above(md) {
		min-height: calc(100vh - #{$headerHeight});
	}
	flex-direction: column;

	.hero-bg {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		width: 100%;
		object-fit: cover;
		z-index: 0;
	}

	.hero-content {
		position: relative;
		color: #fff;
	}

	.hero-content {
		margin-top: auto;
		padding: 80px 0;
		@include below(sm) {
			padding-bottom: 40px;
		}
	}

	.hero-pattern {
		position: relative;
		top: -10px;

		svg {
			@include below(sm) {
				width: 75%;
				height: 100%;
			}
		}
	}

	.hero-title {
		span {
			display: inline-block;
		}
	}
}

.sticky-parent {
	position: relative;
	position: sticky;
	top: 0;
	z-index: 10;
	&.more-space {
		margin: 30px 0;
	}
}

// main header
#main-header {
	background: linear-gradient(to top, #000, transparent);

	&:before {
		content: "";
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background: #fff;
		box-shadow: $base-shadow;
		transition: .3s all ease;
		opacity: 0;
		pointer-events: none;
		z-index: -1;
	}

	&:after {
		content: "";
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background: rgba(0,0,0,.5);
		transition: .3s all ease;
		pointer-events: none;
		opacity: 0;
	}

	.right-side {
		z-index: 1;
	}

	.logo-wrapper {
		display: block;
		position: relative;

		img {
			display: block;
			@include below(md) {
				width: 156px;
			}
		}
		
		.logo-solid {
			transition: .3s all ease;
		}

		.logo-color {
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;
			transition: .3s all ease;
			opacity: 0;
		}

		svg {
			width: 100%;
			height: 100%;
		}
	}

	.header-content {
		padding: 20px 0;
	}

	.header-nav {

		> li {
			@include above(md) {
				&:not(:last-child) {
					margin-right: 30px;
				}
			}
			&:not(.button) {
				a:before {
					content: "";
					position: absolute;
					left: 0;
					right: 0;
					bottom: 0;
					transform: translateY(10px);
					height: 2px;
					background: #000;
					display: none;
				}
			}
			&.current-menu-item a:before {
				display: block;
			}
		}

		a {
			color: #fff;
			font-size: 18px;
			display: inline-block;
			transition: .3s color ease, .3s background-color ease, .3s border-color ease;
			position: relative;
		}

		.close-nav-mobile {
			position: absolute;
			top: 0;
			height: $headerHeightMobile;
			right: $base-padding*1px;
			@include above(md) {
				display: none;
			}
		}

		@include below(md) {
			position: fixed;
			top: 0;
			bottom: 0;
			right: 0;
			width: calc(100% - 65px);
			box-shadow: -6px 0px 20px 0px rgba(0,0,0,.15);
			display: block;
			padding: rem-calc(100 $base-padding);
			padding-top: 120px;
			background: #fff;
			z-index: 11;
			transition: .3s all ease;
			transform: translateX(105%);

			a {
				color: #000;
			}

			.button.white-hollow-blue-fill a {
				border-color: #000;
				&:hover {
					color: #fff;
				}
			}

			> li:not(.button) a {
				padding: 0;
			}

			> li {
				&:not(:last-child) {
					margin-bottom: 25px;
				}
			}
		}
	}

	&.show-header-nav {
		@include below(md) {
			&:after {
				opacity: 1;
			}
			.header-nav {
				transform: none;
			}
		}
	}

	.burger-menu {
		width: 23px;
    height: 18px;
    cursor: pointer;
    position: relative;
    margin-left: auto;
		@include above(md) {
			display: none;
		}

		.bar {
			width: 100%;
			height: 2px;
			background: #fff;
			position: absolute;
			transition: 0.3s all ease;
		}

		.bar--top {
			transform-origin: right top;
			top: 0;
		}

		.bar--middle {
			top: 50%;
			margin-top: -1px;
		}

		.bar--bottom {
			transform-origin: right bottom;
			bottom: 0;
		}

		&.is-active {
			.bar--top {
				transform: translateY(0) translateX(-4px) rotate(-45deg);
			}
			.bar--middle {
				transform: translateX(-10px);
				opacity: 0;
			}
			.bar--bottom {
				transform: translateY(0) translateX(-4px) rotate(45deg);
			}
		}
	}

	// &.theme-light:not(.is-sticky) {
	// 	margin: 30px 0;
	// }

	&.theme-light,
	&.is-sticky {
		background: transparent;
		.header-nav {
			a {
				color: #000;
			}
		}
		.button.white-hollow-blue-fill a {
			border-color: #000;
			&:hover {
				color: #fff;
			}
		}
		.burger-menu .bar {
			background: #000;
		}
	}

	&.is-sticky {
		&:before {
			opacity: 1;
		}
		.logo-solid {
			opacity: 0;
		}
		.logo-color {
			opacity: 1;
		}
	}
}

// start chapter
#start-chapter {
	background: #07385A;
	position: relative;
	overflow: hidden;

	// animated logo
	.animated-logo {
		position: absolute;
		top: 50%;
		left: 50%;
		z-index: 0;
		transform: translate(-50%, -50%);
		@include below(sm) {
			transform: translate(-50%, -50%) scale(.5);
		}
	}

	.container {
		position: relative;
		text-align: center;
	}
}

#building-trust {
	@include below(md) {
		.animated-pattern {
			display: none;
		}
		.building-trust-header {
			margin-bottom: 30px;
		}
	}
}
