$modal-quote-font-size: (
  small: rem-calc(20),
  tablet-portrait: rem-calc(22),
  large: rem-calc(24)
) !default;

$bio-image-width: (
  small: 100%,
  large: rem-calc(400),
  xlarge: rem-calc(500),
  xxlarge: rem-calc(600)
) !default;

$bio-image-center: (
  small: false,
  large: true,
) !default;

.bio-wrapper {
  .bio-info {
    p {
      margin-bottom: rem-calc(25);
      font-size: rem-calc(14);
      line-height: 1.7;

      &.modal-quote {
        margin: rem-calc(35) 0 rem-calc(70);
        line-height: 1.2;
        
        @include -zf-each-breakpoint() {
          font-size: -zf-get-bp-val($modal-quote-font-size, $-zf-size);
        }
      }
    }
  }

  // .bio-image {
    // margin-bottom: rem-calc(15);

    // @include -zf-each-breakpoint() {
    //   width: -zf-get-bp-val($bio-image-width, $-zf-size);

    //   @if(-zf-get-bp-val($bio-image-center, $-zf-size)) {
    //     @include absolute-center;
    //   } @else {
    //     position: relative;
    //     top: 0;
    //     left: 0;
    //     transform: none;
    //   }
    // }
  // }

  .social-share {
    margin: rem-calc(5) 0 rem-calc(8);
  }

  .contact-info {
    margin: rem-calc(30) 0;

    @include breakpoint(tablet-portrait) {
      margin-bottom: 0;
    }

    a {
      display: block;
      color: inherit
    }
  }
}
