$base-padding: 25;
$base-padding-mobile: 15;
$base-shadow: 0 20px 50px 0 rgba(0,0,0,0.15);
$base-shadow-light: 0 10px 25px 0 rgba(0,0,0,0.15);

.small-text-center {
	text-align: unset;
	@include below(md) {
		margin-bottom: 60px;
	}
}

.container {
	padding: rem-calc(0 $base-padding);
	@include below(md) {
		padding: rem-calc(0 15);
	}
}

body {
	&.is-reveal-open {
		overflow: unset !important;
	}
}

svg {
	display: block;
}

.is-sticky {
	top: 0;
	left: 0;
	width: 100%;
	position: fixed;
	position: sticky;
}

.remove-loader {
	display: none;
}

.parallax-bg {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: no-repeat center center / cover;
}

ul {
	list-style: none;
}

.animated-img {
	position: relative;
	&:before {
		content: "";
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background: #f5f4f4;
		transition: .3s all ease-out;
		transform-origin: right;
	}
	&.is-shown {
		&:before {
			transform: scaleX(0);
		}
	}
	img {
		width: 100%;
	}
}

p {
	+ p {
		margin-top: 25px;
	}
}

.anim-item,
.anim-item-alt {
	opacity: 0;
}
