.our-story-section {
  padding: rem-calc(120) 0;

  img {
    margin-top: rem-calc(50);

    @include breakpoint(large) {
      margin: 0;
    }
  }
}