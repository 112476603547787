@include off-canvas-basics;

.js-off-canvas-overlay {
  z-index: $offcanvas-overlap-zindex - 10;
}

// Off-canvas wrapper
.off-canvas-wrapper {
  @include off-canvas-wrapper;
  height: 100%;
}

// Off-canvas container
.off-canvas {
  @include off-canvas-base;
  overflow-x: hidden;

  &.is-open {
    .sub-menu {
      position: relative;
      box-shadow: none;

      li {
        border: 0;
      }
    }
  }
}

.position-right {
  @include off-canvas-position(right, horizontal);
  padding-top: rem-calc(20);

  .icon-hamburger-close {
    margin-right: rem-calc(15);
  }
}

.off-canvas-content {
  @include off-canvas-content;
  height: 100%;
  position: relative;
}


/// Off-canvas styling and settings
/// ==============================================

/// Menu settings!

/// List menu margin
/// type Number
$offcanvas-list-menu-margin: (
  small: rem-calc(0 0 5)
) !default;

/// Menu items font size
/// type Number
$offcanvas-items-font-size: (
  small: rem-calc(14)
) !default;

/// Anchors links - padding bottom-top
/// @type Number
$offcanvas-anchor-bottom-top-padding: (
  small: rem-calc(14)
) !default;

/// Anchors links - padding left-right
$offcanvas-anchor-left-right-padding: (
  small: rem-calc(19)
) !default;

/// ==============================================

.off-canvas-menu {
  padding-top: rem-calc(50);

  ul {
    @include -zf-each-breakpoint {
      margin: -zf-get-bp-val($offcanvas-list-menu-margin, $-zf-size);
    }

    li {

      a {
        @include disable-mouse-outline;
        display: block;

        @include -zf-each-breakpoint() {
          font-size: -zf-get-bp-val($offcanvas-items-font-size, $-zf-size);
          padding: -zf-get-bp-val($offcanvas-anchor-bottom-top-padding, $-zf-size) -zf-get-bp-val($offcanvas-anchor-left-right-padding, $-zf-size);
        }
      }
    }
  }
}
