$footer-column-margin-bottom: (
  small: rem-calc(50),
  tablet-portrait: 0
) !default;

.page-footer {
  background-color: $footer-bg-gray;
  padding: rem-calc(70) 0 rem-calc(97);

  .footer-logo {
   // max-height: rem-calc(65);
    width: 100%;
    max-width: 254px;
    margin-bottom: rem-calc(45);
  }

  .footer-menu {
    li {
      margin-bottom: rem-calc(20);
      font-size: rem-calc(16);
      line-height: 1;
      text-decoration: underline;

      &:hover,
      &:focus {
        text-decoration: none;
      }
    }
  }

  .footer-column {

    @include -zf-each-breakpoint() {
      margin-bottom: -zf-get-bp-val($footer-column-margin-bottom, $-zf-size);
    }
  }

  .footer-heading,
  .contact-info li,
  a,
  p {
    color: $white;
  }

  .footer-heading {
    margin-bottom: rem-calc(30);
    font-family: $body-font-family;
  }

  input {
    border-radius: 0;
    background-color: $solid-dark-gray;
    margin: rem-calc(30) 0 rem-calc(20);
    box-shadow: none;
    transition: none;
    outline: none;
    color: $white;

    &:focus {
      background-color: $solid-dark-gray;
      box-shadow: none;
      border: 1px solid $footer-bg-gray;
    }
  }
}