.sub-header-difference,
.sub-header-contact {
	position: relative;
	
	.hero-pattern {
		transform: scale(1.2) translateX(17px);
		@include below(md) {
			transform: rotate(90deg);
			margin: auto;
			display: block;
			height: 250px;
			svg {
				transform: scale(1.7, 2.4) translateX(30px);
			}
		}
		@include only(sm) {
			svg {
				transform: scaleY(3);
			}
		}
	}
}

.meet-team {
	@include below(md) {
		padding: rem-calc(75) 0;
	}

	.animated-logo svg {
		margin: auto;
		width: 100%;
		@include below(md) {
			margin: 25px 0;
			height: 100%;
		}
	}
}

// sections
.featured-section {
	padding: 100px 0;
	@include below(md) {
		padding: 50px 0;
	}
	.featured-card-section {
		&:not(:last-child) {
			margin-bottom: 100px;
			@include below(md) {
				margin-bottom: 50px;
			}
		}

		.flex > div {
			&:not(:last-child) {
				@include below(md) {
					margin-bottom: 50px;
				}
				@include below(sm) {
					margin-bottom: 35px;
				}
			}
		}
	}

	.attachment-full {
		object-fit: cover;
	}

	.card {
		.inner {
			padding: 50px;
			@include below(md) {
				padding: 25px;
			}
			&.card-transparent {
				@include below(md) {
					padding: 0;
				}
			}
			&:not(.card-transparent) {
				background: #fff;
				box-shadow: $base-shadow-light;
			}
		}
	}
}

.steps {
	text-align: left;
}
