.home-header {
  height: 100%;
  min-height: calc(100vh - 41px);
  overflow: hidden;
  position: relative;
  margin-bottom: -100px;
  @include breakpoint(small) {
    margin-bottom: -130px;
  }
  @include breakpoint(large) {
    margin-bottom: -160px;
  }
  .logo-wrapper {
    background: url('../assets//images/home-linesegments.png') no-repeat top left;
    @include breakpoint(small only) {
      //background: none;
      background-position-y: -240px;
      //-webkit-background-size: 40%;
      // background-size: 40%;
    }
    @include breakpoint(medium only) {
      //   background: none;
      background-position-x: 25px;
    }
    @include breakpoint(large only) {
      background-position-x: 25px;
    }
    @include breakpoint(xlarge only) {
      background-position-x: 25px;
    }

  }

  video,
  #home-video-header {
    position: absolute;
    top: 0;
    left: 0;
		right: 0;
		bottom: 0;
  }
  #home-video-header {
    .bg-image {
      width: 100%;
			height: 100%;
			object-fit: cover;

      @include breakpoint(large only) {
        height: 100vh;
        width: auto;
      }
    }
  }
  video {
    @include breakpoint(small only) {
      top: -171px;
      left: 28%;
      width: 60%;
    }

    @include breakpoint(large) {
      top: -117px;
      left: 257px;
      width: 70%;
    }
    @include breakpoint(xxlarge) {
      top: 0;
      left: 0;
      width: 100%;
    }
  }

  .logo-wrapper {
    min-height: calc(100vh - 201px);
    z-index: 10;
  }
  h1.home-banner-title {
    color: #fff;
    position: absolute;
    bottom: 0;
    font-size: 2rem;
    padding: 0 60px 70px 0;
    width: 350px;
    br {
      display: none;
    }

    @include breakpoint(medium) {
      left: 25px;
      //bottom: 120px;
      bottom: 0;
      width: 450px;
      font-size: 3rem;
      br {
        display: block;
      }
    }
    @include breakpoint(large) {
      left: 25px;
      bottom: 0;
      //bottom: 125px;

    }
    @include breakpoint(xxlarge) {
      left: 0;
      //bottom: 200;
      bottom: 0;

    }
  }
  .header-logo-image {
    position: absolute;
    top: 40%;
    @include breakpoint(small only) {
      top: 30%;
    }
    width: calc(100% - 20px);
    z-index: map-get($zindexes, home-header-logo);

    @include breakpoint(tablet-portrait) {
      width: auto;
    }
  }
}
