.start-chapter {
  padding: 300px 0;

	@include below(md) {
		padding: 150px 0;
	}

  .start-chapter-text {
    color: $white;
  }

	.button {
		transition: .3s background ease, .3s border-color ease;
		&:hover {
			background: #cb5e41;
			border-color: #cb5e41;
			color: #fff;
		}
	}
}
