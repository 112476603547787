.testimonial {
  background: url('../img/testimonial-bg.png') no-repeat center left;
  background-size: cover;
  padding: rem-calc(145) 0;

  blockquote {
    font-size: rem-calc(36);
    font-weight: 300;
  }

  cite {
    text-align: right;
    margin-top: rem-calc(60);

    span {
      position: relative;
      font-size: rem-calc(24);
      color: $white;
      font-style: normal;
      font-family: $header-font-family;

      &:before {
        content: '';
        display: block;
        position: absolute;
        left: rem-calc(-155);
        top: rem-calc(10);
        height: rem-calc(14);
        background-color: $secondary-color;
        width: rem-calc(115);
        border-radius: $line-separator-border-radius;
      }
    }
  }
}