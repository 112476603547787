/// Ajax loader settings
/// ====
/// Ajax loader width/height
$ajax-loader-dims: rem-calc(40);

/// A bit custom solution for loaders
/// Done this very bad, i'm gonna reformat it later @todo @nikola
@mixin ajax-loader-wrapper($min-height) {
  display: none;
  position: relative;
  min-height: $min-height;
}

@mixin main-loader($small: false, $position: 'absolute', $center: false) {
  display: block;
  z-index: 100;

  @if($position) {
    position: absolute;
  } @else {
    position: relative;
  }

  @if($center) {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .loader {
    margin: 0 auto;
    position: relative;

    @if($small) {
      height: $ajax-loader-dims/2;
      width: $ajax-loader-dims/2;
    } @else {
      height: $ajax-loader-dims;
      width: $ajax-loader-dims;
    }

    &::before {
      content: '';
      display: block;
      padding-top: 100%;
    }
  }

  .circular-loader {
    animation: rotate 2s linear infinite;
    height: 100%;
    left: 0;
    margin: auto;
    position: absolute;
    top: 0;
    transform-origin: center center;
    width: 100%;

    .loader-path {
      animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
      stroke-dasharray: 150,200;
      stroke-dashoffset: -10;
      stroke-linecap: round;
    }
  }
}

/// ====

.ajax-loader-wrapper {
  @include ajax-loader-wrapper(500px);
}

.ajax-loader-button-wrapper {
  @include ajax-loader-wrapper(20px);
}

.ajax-loader-smaller-wrapper {
  @include ajax-loader-wrapper(86px);
}

.main-loader {
  @include main-loader(false, 'absolute', true);
}
.small-loader { // search input loaders
  @include main-loader(true, 'absolute', false);
  display: none;
  right: 30px;
  top: 5px;
}

.button-loader { // button loaders
  @include main-loader(true, 'relative', false);
  left: 50%;
  transform: translateX(-50%);
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1,200;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 89,200;
    stroke-dashoffset: -35;
  }

  100% {
    stroke-dasharray: 89,200;
    stroke-dashoffset: -124;
  }
}

@keyframes color {
  0% {
    stroke: $primary-color;
  }

  100% {
    stroke: $primary-color;
  }
}
