$featured-number-card-offset: (
  small: rem-calc(20),
  tablet-portrait: rem-calc(40),
  large: rem-calc(115)
) !default;


.featured-section {

  .container-fluid > .row {
    max-width: 100%;
  }

  .featured-card-section .blue-line,
  .featured-card-section .purple-line,
  .featured-card-section .light-purple-line{
    @include -zf-each-breakpoint() {
      margin-top: -zf-get-bp-val($featured-number-card-offset, $-zf-size);
    }
  }

  .objectives {
    margin-bottom: rem-calc(32);

    @include breakpoint(tablet-portrait) {
      margin-bottom: 0;
    }
  }

  .featured-card-section {
    // padding: rem-calc(40) 0 rem-calc(60);

    // @include breakpoint(large) {
    //   padding: rem-calc(40) 0 rem-calc(90);
    // }

    &.card-section-in-middle {
      padding: rem-calc(90) 0 rem-calc(90);
    }

    .featured-number {
      color: $dirty-white;
    }
  }

  .featured-default-text {
    margin-bottom: rem-calc(20);

    &.last {
      margin-bottom: rem-calc(50);
    }
  }

  .featured-list {
    font-weight: 500;
  }

  .offset-featured {

    @include breakpoint(tablet-portrait) {
      margin: rem-calc(140) 0 0;
    }
  }

  .centered-image,
  .featured-default-image-wrapper,
  .centered-list {
    margin: rem-calc(60) 0;

    @include breakpoint(tablet-portrait) {
      @include absolute-center;
      margin: 0;
      width: calc(100% - 30px);
    }
  }

  .featured-default-content {

    @include breakpoint(tablet-portrait) {
      @include vertical-center;
      width: calc(100% - 30px);
    }
  }

  .featured-default-section {
    // padding: rem-calc(90) 0;

    @include breakpoint(large) {
      padding: rem-calc(120) 0;
    }

    .featured-number {
      color: $solid-medium-gray;
    }
    .row{
      @include breakpoint(small only){
       .columns {

          padding-left: 30px;
          padding-right: 30px;
           .columns {
             padding-right: 0.625rem;
             padding-left: 0.625rem;
           }
         .steps{
          .step-wrapper{
            margin-top: 30px;
          }
         }
        }
      }
    }


  }

  .steps {
    .step-number {
      margin-bottom: rem-calc(30);
    }

    .step-wrapper {
      margin-bottom: rem-calc(50);
    }

    .steps-icon {
      margin-top: rem-calc(20);

      @include breakpoint(tablet-portrait) {
        @include vertical-center;
        margin-top: 0;
        width: rem-calc(150);
      }
    }
  }

  .featured-number {
    font-size: rem-calc(72);
    line-height: 1;
    font-family: $header-font-family;
    display: inline-block;

    &.offset-featured-number {
      margin-bottom: rem-calc(20);
    }
  }
}
// @include breakpoint(small only){
//   .featured-section{
//     padding: 0;
//     .featured-card-section,
//     .featured-default-section{
//       padding: 75px 0 75px;
//     }
//   }
// }
