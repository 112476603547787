$fixed-card-height: (
  small: auto,
  tablet-portrait: rem-calc(515),
  large: rem-calc(660)
) !default;

$fixed-card-image-column: (
  small: block,
  tablet-portrait: table-row,
  large: block
) !default;

.card {
  height: 100%;

  &.fixed-card {
    @include -zf-each-breakpoint() {
      height: -zf-get-bp-val($fixed-card-height, $-zf-size);
    }
  }

  .inner{
    background-color: $white;
    box-shadow: 0 0 10px rgba(0, 0, 0, .3);
    // padding: rem-calc(30);
    height: inherit;
    display: block;
    min-height: fit-content;

    &.card-transparent {
      background-color: transparent;
      box-shadow: none;

      .featured-number {
        color: $solid-medium-gray;
      }
    }
  }

}
