.embracing-clients-section {
  //background-color: $embracing-client-bg;
  background-color: transparent;
  padding: rem-calc(205) 0 rem-calc(105);
  @include breakpoint(small only) {
    background-color: #252424;
      padding:75px 0 0;
      h2.white-color{
        margin-top: 0;
      }
  }
  p {
    font-size: rem-calc(18);

    &.embracing-text {
      margin-bottom: rem-calc(70);
    }
  }

  .embracing-client-features {
    margin-top: rem-calc(40);

    @include breakpoint(tablet-portrait) {
      margin-top: 0;
    }
  }

  .embracing-client-item-icon-wrapper {
    min-height: rem-calc(170);
  }

  .embracing-client-item-text {
    min-height: rem-calc(54);
    margin: rem-calc(30) 0 rem-calc(40);
  }
}