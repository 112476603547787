.contact-additional-info {
  padding: rem-calc(70) 0 rem-calc(170);

  .contact-info-wrapper {
    margin-bottom: rem-calc(40);

    @include breakpoint(tablet-portrait) {
      @include vertical-center;
      margin-bottom: 0;
      width: calc(100% - 30px);
    }

    p {
      font-size: rem-calc(18);
    }

    .address-info {
      margin: rem-calc(18) 0;
    }

    .follow-us {
      color: $body-font-color;
      vertical-align: bottom;
      line-height: 1;
    }
  }
}