.meet-team {
  background: url('../img/meet-our-team-section-bg.jpg') no-repeat center center;
  padding: rem-calc(150) 0;

  .color-fill-transition {
    transition: color $transition-speed-normal linear, fill $transition-speed-normal linear;
  }

  &.active {

    .fill-01,
    .fill-06,
    #fill-01,
    #fill-06 {
      color: $secondary-color; //#cb5e41
      // fill: $secondary-color; //#cb5e41
      stroke: $secondary-color; //#cb5e41
    }

    .fill-02,
    #fill-02 {
      color: get-color(brown); //#d79d65
      stroke: get-color(brown); //#d79d65
    }

    .fill-03,
    .fill-05,
    #fill-03,
    #fill-05 {
      color: #82A5D7;
      stroke: #82A5D7;
    }

    .fill-04,
    #fill-04 {
      color: #805774;
      stroke: #805774;
    }
  }

  #meet-team-svg {
		margin: auto;
		width: 100%;
		pointer-events: none;
		&.transition {
			pointer-events: auto;
			path {
				transition: .3s all ease;
			}
		}
		// @include below(md) {
		// 	margin: 50px 0;
		// }
    // max-height: rem-calc(586);
    // position: relative;
    // @include breakpoint(medium) {
    //   top: -30px;
    //   left: -35px;
    // }
    // @include breakpoint(large) {
    //   top: -35px;
    //   left: -30px;
    // }
    // @include breakpoint(xxlarge) {
    //   top: -15px;
    //   left: -30px;
    // }
  }

  .meet-team-title {
    color: $white;
    font-size: rem-calc(24);
    margin-bottom: rem-calc(115);
  }

  .number-items {
    padding: 0;
    margin: 0;
    width: 100%;

    @include breakpoint(large) {
      display: table;
    }

    .number-item-wrapper {
			&:not(:last-child) {
				margin-bottom: rem-calc(50);
			}
    }

    .number-item {
      text-align: center;

      @include breakpoint(large) {
        display: table-row;
      }

      span {
        vertical-align: middle;

        @include breakpoint(large) {
          display: table-cell;
        }

        &.number-item-text {
          font-size: rem-calc(18);
          max-width: rem-calc(125);
          text-align: left;
          padding-left: rem-calc(25);
        }

        &.number {
          font-family: $header-font-family;
          font-size: rem-calc(36);
        }
      }
    }
  }

  .button {
    margin-top: rem-calc(30);
  }
}
