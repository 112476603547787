@mixin opacity($opacity: 0.5) {
  filter: alpha(opacity=$opacity*100);
  filter: progid:DXImageTransform.Microsoft.Alpha(opacity=#{$opacity*100});
  opacity: $opacity;
}
@mixin transition($transition...) {
  -moz-transition:    $transition;
  -o-transition:      $transition;
  -webkit-transition: $transition;
  transition:         $transition;
}
video {
  width: 100%;
}
img[src*=".svg"] {
 // width: 100%;
}
@include breakpoint(medium down) {
  .small-text-left {
    text-align: left; }
  .small-text-right {
    text-align: right; }
  .small-text-center {
    text-align: center; }
  .small-text-justify {
    text-align: justify; }
}
$container-widths: (
  small: rem-calc(1200),
  xxlarge: rem-calc(1440)
);

.container {
  margin: 0 auto;

  @include -zf-each-breakpoint {
    max-width: -zf-get-bp-val($container-widths, $-zf-size);
  }
}

.container-fluid {
  @include clearfix;

  @include breakpoint(tablet-portrait) {
    padding: 0 rem-calc(40);
  }

  .custom-columns {
    width: calc(50% - 20px);
    float: left;

    &:first-child {
      padding-right: rem-calc(40);
    }
  }
}

$zindexes: (
  home-header-logo: 99,
  team-more-button: 50,
  modal-close: 100,
  sticky: 80,
) !default;

$line-separator-border-radius: 10px !default;

$transition-speed-fast: .25s;
$transition-speed-normal: .5s;
$transition-speed-slow: .75s;

%line {
  display: block;
  height: rem-calc(15);
  width: rem-calc(115);
  border-radius: $line-separator-border-radius;
}

body {
  transition: background-color $transition-speed-fast linear;
}

.blue-line {
  @extend %line;
  background-color: $primary-color;
}

.purple-line {
  @extend %line;
  background-color: $custom-purple;
}

.remove-left-padding {
  @include breakpoint(tablet-portrait) {
    padding-left: 0;
  }
}

.remove-right-padding {
  @include breakpoint(tablet-portrait) {
    padding-right: 0;
  }
}

.light-purple-line {
  @extend %line;
  background-color: $submit-button-purple;
}

.custom-light-purple-color {
  color: $submit-button-purple;
}

.full-height {
  min-height: 100vh;
  height: 100%;
}

.sticky-full-width {
  width: 100%;
  z-index: map-get($zindexes, sticky);
  transition: background-color $transition-speed-fast linear;

  &.is-stuck {
    background-color: $white;
    box-shadow: 0 0 5px 0 $white;
    top: 0;
    left: 0;
    z-index: map-get($zindexes, sticky);
  }
}

.underlined {
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}

.dirty-white-bg {
  background-color: $dirty-white;
}

.white-color {
  color: $white;
}

.all-caps {
  text-transform: uppercase;
}

.whatinput-types-mouse {
  .phone-links {
    //pointer-events: none;
  }
}

.full-page {
  min-height: 1px;
  height: auto;

  @include breakpoint(tablet-portrait) {
    min-height: 100vh;
    height: 100%;
  }
}

.relative-wrapper {
  position: relative;
}

.medium-grayish {
  color: $medium-grayish;
}

$map-height: (
  small: rem-calc(300),
  tablet-portrait: rem-calc(450),
  large: rem-calc(540)
) !default;

#map {
  @include -zf-each-breakpoint() {
    height: -zf-get-bp-val($map-height, $-zf-size);
  }
}
.sub-header {
  .sub-header-wrapper {
    &.sub-header-about {
      background-repeat: no-repeat;
      background-position: center right;
      background-size: cover;
    }
  }
}
@include breakpoint(small only) {
.mobile-margins {

  &.lets-talk{
      margin-top: 30px;
    }
  }
.sub-header{
  .sub-header-wrapper{
    &.sub-header-difference,
    &.sub-header-about,
    &.sub-header-contact{
      min-height: 300px;
    }
    &.sub-header-about{


        background-size: 100%;
        background-position: 100% 100%;
        background-color: #fff;

    }
  }
}

}


.menu li:first-child a.menu-item {
  padding-right: 0px;
}
.padding-left-30{
  padding-left: 30px;
}
.margin-bottom-90{
  margin-bottom: 90px;
}

// .logo-white{
//   display:none;
//   .page-template-page-home &{
//     display:block;
//   }
// }
// .logo-wrapper{
//   position: relative;
//   top: -16px;
//   .logo-white,.logo-color{
//     position: absolute;
//   }
// }
.page-template-page-home{
  .logo-white,
  .logo-color{
    @include transition(all 250ms ease-in);
  }
  .menu a{
    color: $white;
    border-color: $white;
    @include breakpoint(medium only){
      color: $black;
      border-color: $black;
      border: none;
    }
    @include breakpoint(small only){
      color: $black;
      border-color: $black;
      border: none;
    }
  }
  .menu {
    .white-hollow-blue-fill {
      border-color: $white;
    }
  }
  .logo-white{
    @include opacity(1);
    .is-stuck &{
      @include opacity(0);
    }
  }
  // .logo-color{
  //   @include opacity(0);
  //   .is-stuck &{
  //     @include opacity(1);
  //   }
  // }
  .is-stuck{
    .logo-color{
      @include opacity(1);
    }
    .logo-white{
      @include opacity(0);
    }
    .menu a{
      color: $black;
      border-color: $black;
    }

    .menu .white-hollow-blue-fill {
      border-color: $black;
    }
  }

}
body{
  &.nav-is-stuck{
    padding-top: 130px;
    &.page-template-page-home{
      padding-top: 0;
    }
    @include breakpoint(small){
     padding-top: 140px;
      &.page-template-page-home{
        padding-top: 0;
      }
    }
    @include breakpoint(large){
     padding-top: 160px;
      &.page-template-page-home{
        padding-top: 0;
      }
    }

    .white-hollow-blue-fill {
      border-color: $black;
    }
    .home-header{
      margin-bottom: 0;
    }
  }
}

.menu a{
  @include breakpoint(medium only){
    color: $black;
    border-color: $black;
    border: none!important;
  }
  @include breakpoint(small only){
    color: $black;
    border-color: $black;
    border: none!important;
  }
}
