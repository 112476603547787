//.whatinput-types-mouse {
//  .team-section-second {
//    .team-member-image {
//      transition: max-width $transition-speed-normal ease-in-out, filter $transition-speed-normal ease-in-out;
//
//      &:hover {
//        max-width: 120%;
//        -webkit-filter: brightness(120%);
//      }
//    }
//  }
//}

.team-section {
  padding: rem-calc(90) 0;
  @include breakpoint(medium down) {
    //padding: rem-calc(15) 0;
  }

  &.team-section-second {
    .team-member-wrapper {
      // margin-bottom: rem-calc(80);
      // @include breakpoint(medium down) {
      //   margin-bottom: rem-calc(20);
      // }
    }

    .team-member-image-wrapper {
      cursor: auto;
      overflow: hidden;

      @include -zf-each-breakpoint() {
        max-height: rem-calc(270);
      }
    }
  }

  .team-member-wrapper {
    margin-bottom: rem-calc(60);
    @include breakpoint(medium down) {
      margin-bottom: rem-calc(20);
    }
  }

  .team-member-image-wrapper {
    cursor: pointer;
    margin: 0 0 rem-calc(10);
    position: relative;

    .more-button {
      position: absolute;
      bottom: rem-calc(30);
      right: 0;
      z-index: map-get($zindexes, team-more-button);
    }
  }

  .team-member-name {
    font-size: rem-calc(24);
    font-weight: 300;
  }

  .team-member-position {
    font-size: rem-calc(18);
  }

  .team-member-phone,
  .team-member-email {
    font-size: rem-calc(14);
    word-wrap: break-word;
    line-height: 1;
    padding: rem-calc(5) 0;

    @include breakpoint(medium down) {
      font-size: rem-calc(12);
    }

    a {
      color: inherit;
    }
  }

  .team-member-email {
    text-decoration: underline;
  }
}
