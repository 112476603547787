$statistics-number-font-size: (
  small: rem-calc(48),
  tablet-portrait: rem-calc(48),
  large: rem-calc(64),
  xlarge: rem-calc(82)
) !default;

$statistics-number-width: (
  small: 35%,
  tablet-portrait: 50%,
  large: 65%,
  xxlarge: 60%
) !default;

.building-trust {
  //background: url('../img/building-trust-bg.jpg') no-repeat center center;
  //background-size: cover;
  background-color: transparent;
  padding: 100px 0;
  @include breakpoint(small only) {
    background-color: #d05e46;
    padding: 75px 0 75px;
    h2.white-color{
      margin-top: 0;
    }
  }
  // .statistics-wrapper {
  //   @include breakpoint(tablet-portrait) {
  //     &:before {
  //       content: '';
  //       display: block;
  //       background: url('../assets/svg/building-trust-pattern.svg') no-repeat center center;
  //       height: rem-calc(390);
  //       width: rem-calc(550);
  //       position: absolute;
  //       top: -450px;
  //       right: 0;
  //       opacity: 0;
  //       pointer-events: none;
  //       transition: opacity $transition-speed-fast linear;
  //     }
  //   }
  // }

  // &.active .statistics-wrapper:before {
  //   opacity: 1;
  // }

  p {
    font-size: rem-calc(18);

    &.building-trust-text {
      margin-bottom: rem-calc(70);
    }
  }

  .statistics-holder {

    .statistics-wrapper {
      display: table;
      width: 100%;
      margin-bottom: rem-calc(20);
    }

    .statistics-number {
      font-family: $header-font-family;
      display: table-cell;
      vertical-align: middle;
      line-height: 1;

      @include -zf-each-breakpoint() {
        font-size: -zf-get-bp-val($statistics-number-font-size, $-zf-size);
        width: -zf-get-bp-val($statistics-number-width, $-zf-size);
      }
    }

    .statistics-description {
      font-size: rem-calc(18);
      display: table-cell;
      vertical-align: middle;
    }
  }

}

// additional
.building-trust-header {
	margin-bottom: 100px;
	h2 {
		margin: 0
	}
	svg {
		width: 100%;
		height: 100%;
	}
}
