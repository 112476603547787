// .sub-header {
//   padding: 0 0 rem-calc(40);

//   .sub-header-wrapper {
//     min-height: rem-calc(540);
//     padding: rem-calc(60) 0 rem-calc(65);
//     .page-difference &{
//       @include breakpoint(medium down) {
//         // All CSS in here goes inside the media query
//         background: #fff!important;

//       }
//     }


//     .subheader-subtitle {
//       font-weight: 100;
//       font-size: rem-calc(24);
//     }
//   }
// }

.sub-header-wrapper {
	margin-bottom: 50px;
}

.sub-header {
	background: #fff;
	box-shadow: $base-shadow;
	position: relative;
	overflow: hidden;

	&-content {
		padding: 50px;
		@include below(md) {
			padding: 30px;
		}
	}

	&-pattern {
		height: 100%;

		svg {
			display: block;
			width: 100%;
			height: 100%;
		}
	}
}
