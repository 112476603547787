.top-header {
	background: #33302f;
	position: relative;
	z-index: 1;
	width: 100%;

  p {
    color: $medium-gray;
    font-size: rem-calc(14);
    padding: rem-calc(10) 0;
  }

  .phone-links {
    margin-left: rem-calc(20);
    @include breakpoint(small only) {
      margin-left: 0;
    }
  }

  a {
    color: $white;
    display: inline-block;
  }

  .client-login-link {
    padding: rem-calc(10 15);
    font-size: rem-calc(14);

    &.active {
      background-color: $white;
      color: $black
    }
  }
}
