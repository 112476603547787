.intro-section {
  padding: rem-calc(100) 0;
	// background: #fff;

  .intro-text {
    font-size: rem-calc(24);
    font-weight: 100;
    margin-bottom: rem-calc(20);

    @include breakpoint(tablet-portrait) {
      margin-bottom: 0;
    }
  }

  .family-photo-section {
    padding-top: rem-calc(74);
    padding-bottom: rem-calc(70);
  }
}

@include breakpoint(small only) {
  .intro-section{
    padding-bottom: 0;
  }

}
