$primary-bg-color-hover: #073f67;
$primary-color-hover: #cbc9c9;
$brown-bg-color-hover: #c18d5a;
$brown-color-hover: #cbc9c9;

@mixin horizontal-open($color) {
  position: relative;

  &:before,
  &:after {
    background-color: $color;
    content: "";
    width: 0;
    height: 100%;
    opacity: 0;
    position: absolute;
    top: 0;
    pointer-events: none;
    z-index: 1;
    transition: all $transition-speed-fast;
  }

  &:before {
    left: 50%;
  }

  &:after {
    right: 50%;
  }

  .button-text {
    position: relative;
    z-index: 2;
  }

  &:hover {
    background-color: transparent;

    &:before,
    &:after {
      width: 50%;
      opacity: 1;
    }
  }
}


.button {
  @include button-base;
  text-decoration: none;

  &.primary {
    @include button-style($primary-color, $primary-bg-color-hover, $white);
    //box-shadow: 1px 1px darken($secondary-color, 10%);
    padding: rem-calc(19) rem-calc(30);
    line-height: rem-calc(21);
    font-size: rem-calc(16);
    font-weight: 400;

    &:hover {
      box-shadow: none;
      color: $primary-color-hover;
    }
  }

  &.brown {
    @include button-style(get-color(brown), $brown-bg-color-hover, $black, $brown-color-hover);
    box-shadow: 1px 1px darken($primary-color, 10%);
    padding: rem-calc(19) rem-calc(30);
    line-height: rem-calc(21);
    font-size: rem-calc(16);
    font-weight: 400;

    &:hover {
      color: $brown-color-hover;
      box-shadow: none;
    }
  }

  &.white-hollow {
    @include button-style(transparent, $secondary-color, $white);
    @include button-hollow-style($white, $border-width: 2px);
    // @include horizontal-open($secondary-color);
    width: rem-calc(260);
    font-size: rem-calc(24);
    line-height: rem-calc(60);
    padding: 0;

    // &:hover {
    //   border-color: $secondary-color;
    //   color: $white;
    // }
  }

  &.white-hollow-blue-fill {
    @include button-style(transparent, $primary-color, $black);
    // @include button-hollow-style($black, $border-width: 2px);
    // @include horizontal-open($primary-color);
    padding: 0;
    line-height: 1;
    font-weight: 400;
    font-size: rem-calc(16);
    display: inline-block;
    text-decoration: none;

    a {
      padding: rem-calc(15 30);
			transition: .3s all ease;
			border: 1px solid #fff;
    }

    &:hover {
			a {
				border-color: $primary-color;
				background: $primary-color;
			}
    }
  }

  &.subscribe {
    @include button-style($solid-dark-gray, darken($solid-dark-gray, 10%), $white);
    padding: rem-calc(6);
    line-height: 1;
    font-size: rem-calc(16);

    &:hover {
      color: $light-gray;
    }
  }

  &.more-button {
    @include button-style($secondary-color, lighten($secondary-color, 10%), $white);
    padding: rem-calc(7 40);
    font-size: rem-calc(24);
    line-height: 1;
    border-radius: rem-calc(20) 0 0 rem-calc(20);
    transition: color $transition-speed-fast, padding $transition-speed-fast;

    &:hover {
      padding-left: rem-calc(60);
    }
  }
}

.modal-close {
  @include svg-bg-close;
  @include svg-bg-close-dims;
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  z-index: map-get($zindexes, modal-close);
}

.hamburger-button {
  @include svg-bg-hamburger;
  @include svg-bg-hamburger-dims;
  cursor: pointer;
}

.hamburger-close-button {
  @include svg-bg-hamburger-close;
  @include svg-bg-hamburger-close-dims;
  cursor: pointer;
}

.linkedin-logo {
  @include svg-bg-linkedin-logo;
  @include svg-bg-linkedin-logo-dims;
  cursor: pointer;
}
